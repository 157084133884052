import "../PagesCss/Default.css";
import React, { Profiler, useState } from "react";
import SideBar from "./SideBar";
import { useNavigate } from "react-router";
import Navbar from "./Navbar";
import axios from "axios";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
axios.defaults.headers.common["access-token"] =
  JSON.parse(localStorage.getItem("userData"))?.accessToken || "";
const Profile = (props) => {
  const storedData = JSON.parse(localStorage.getItem("userData")) || {};
  const [formData, setFormData] = useState({
    userId: JSON.parse(localStorage.getItem("userData")).userId || 0,
    firstName: storedData.firstName || "",
    lastName: storedData.lastName || "",
    companyName: storedData.companyName || "",
    address: storedData.address || "",
    postCode: storedData.postCode || "",
    email: storedData.email || "",
    supplieremail1: storedData.supplieremail1 || "",
    supplieremail2: storedData.supplieremail2 || "",
    supplieremail3: storedData.supplieremail3 || "",
    phone: storedData.phone || "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form data:", formData);

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/profile/put`,
        formData
      );

      console.log("API Response:", response);
      NotificationManager.success("User updated successfully", "Info");
      // Check if the response is successful
      if (response.status === 200) {
        localStorage.setItem("userData", JSON.stringify(formData));
        console.log("Data updated successfully");
      } else {
        console.error("Error updating data:", response.statusText);
        NotificationManager.error("Error Updating Data.", "error");
      }
    } catch (error) {
      console.error("Error updating data:", error.message);
      NotificationManager.error("Error Updating Data.", "error");
    }
  };
  const currentYear = new Date().getFullYear();
  return (
    <>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta name="description" content="" />
      <meta name="author" content="" />
      <title>Dashboard</title>
      <link
        href="https://cdn.jsdelivr.net/npm/simple-datatables@latest/dist/style.css"
        rel="stylesheet"
      />
      <link href="css/styles.css" rel="stylesheet" />
      <link rel="icon" type="image/x-icon" href="assets/img/favicon.png" />
      <Navbar toggleMobileMenu={props.toggleMobileMenu} />
      <div id="layoutSidenav">
        <SideBar
          setMobileMenuOpen={props.setMobileMenuOpen}
          isMobileMenuOpen={props.isMobileMenuOpen}
        />

        <div id="layoutSidenav_content">
          <main>
            <header className="page-header page-header-compact page-header-light border-bottom bg-white mb-4">
              <div className="container-xl px-4">
                <div className="page-header-content">
                  <div className="row align-items-center justify-content-between pt-3">
                    <div className="col-auto mb-3">
                      <ol className="breadcrumb px-3 py-2 rounded mb-0">
                        <li className="breadcrumb-item">
                          <a href="dashboard.html">Home</a>
                        </li>
                        <li className="breadcrumb-item active">
                          Manage Account - Profile
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            {/* Main page content*/}
            {/* Main page content*/}
            <div id="khubu75" className="container-xl px-4 mt-4">
              {/* Account page navigation*/}
              <nav className="nav nav-borders" id="profileMob">
                <a
                  className="nav-link active ms-0"
                  onClick={() => {
                    navigate("/Profile");
                  }}
                >
                  Profile
                </a>
                <a
                  className="nav-link"
                  onClick={() => {
                    navigate("/Security");
                  }}
                >
                  Security
                </a>
              </nav>
              <div className="container-xl">
                <div className="card"></div>
                <br />
                <div className="container-xl">
                  <div className="card">
                    <div className="card-header">Account details</div>
                    <div className="card-body">
                      <form onSubmit={handleSubmit}>
                        {/* Form Row*/}
                        <div className="row gx-3">
                          <div className="col-md-6">
                            {/* Form Group (first name)*/}
                            <div className="mb-3">
                              <label
                                className="small mb-1"
                                htmlFor="inputFirstName"
                              >
                                First Name
                              </label>
                              <input
                                className="form-control"
                                id="firstName"
                                type="text"
                                placeholder="Enter first name"
                                value={formData.firstName}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            {/* Form Group (last name)*/}
                            <div className="mb-3">
                              <label
                                className="small mb-1"
                                htmlFor="inputLastName"
                              >
                                Last Name
                              </label>
                              <input
                                className="form-control"
                                id="lastName"
                                type="text"
                                placeholder="Enter last name"
                                value={formData.lastName}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row gx-3">
                          <div className="col-md-6">
                            {/* Form Group (first name)*/}
                            <div className="mb-3">
                              <label
                                className="small mb-1"
                                htmlFor="inputCompanyName"
                              >
                                Company Name
                              </label>
                              <input
                                className="form-control"
                                id="companyName"
                                type="text"
                                aria-describedby="companyHelp"
                                placeholder="Enter company name"
                                value={formData.companyName}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            {/* Form Group (last name)*/}
                            <div className="mb-3">
                              <label
                                className="small mb-1"
                                htmlFor="inputLastName"
                              >
                                Phone
                              </label>
                              <input
                                required
                                className="form-control"
                                id="phone"
                                type="text"
                                placeholder="Enter phone"
                                value={formData.phone}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        {/* Form Group (email address)            */}
                        {/* Form Group (email address)            */}
                        <div className="mb-3">
                          <label className="small mb-1" htmlFor="address">
                            Address
                          </label>
                          <input
                            className="form-control"
                            id="address"
                            type="text"
                            aria-describedby="address"
                            placeholder="Enter addess"
                            value={formData.address}
                            onChange={handleChange}
                          />
                        </div>
                        {/* Form Group (email address)            */}
                        <div className="mb-3">
                          <label className="small mb-1" htmlFor="postcode">
                            Post Code
                          </label>
                          <input
                            className="form-control"
                            id="postCode"
                            type="text"
                            aria-describedby="postcode"
                            placeholder="Enter post code"
                            value={formData.postCode}
                            onChange={handleChange}
                          />
                        </div>
                        {/* Form Group (email address)            */}
                        <div className="mb-3">
                          <label
                            className="small mb-1"
                            htmlFor="inputEmailAddress"
                          >
                            Email
                          </label>
                          <input
                            className="form-control"
                            id="email"
                            type="email"
                            aria-describedby="emailHelp"
                            placeholder="Enter email address"
                            value={formData.email}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            className="small mb-1"
                            htmlFor="inputEmailAddress"
                          >
                            Supplier email1
                          </label>
                          <input
                            className="form-control"
                            id="supplieremail1"
                            type="email"
                            aria-describedby="emailHelp"
                            placeholder="Enter email address"
                            value={formData.supplieremail1}
                            onChange={handleChange}
                          />
                        </div>{" "}
                        <div className="mb-3">
                          <label
                            className="small mb-1"
                            htmlFor="inputEmailAddress"
                          >
                            supplier email2
                          </label>
                          <input
                            className="form-control"
                            id="supplieremail2"
                            type="email"
                            aria-describedby="emailHelp"
                            placeholder="Enter email address"
                            value={formData.supplieremail2}
                            onChange={handleChange}
                          />
                        </div>{" "}
                        <div className="mb-3">
                          <label
                            className="small mb-1"
                            htmlFor="inputEmailAddress"
                          >
                            Supplier email3
                          </label>
                          <input
                            className="form-control"
                            id="supplieremail3"
                            type="email"
                            aria-describedby="emailHelp"
                            placeholder="Enter email address"
                            value={formData.supplieremail3}
                            onChange={handleChange}
                          />
                        </div>
                        <button className="btn btn-primary" type="submit">
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <footer className="footer-admin mt-auto footer-light">
            <div className="container-xl px-4">
              <div className="row">
                <div className="col-md-6 small">
                  Copyright © Roof Pal {currentYear}
                </div>
                <div className="col-md-6 text-md-end small"></div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Profile;
