import "../PagesCss/Default.css";
import React, { useState } from "react";
import favicon from "../assets/img/favicon.png";
import { useNavigate } from "react-router";
import axios from "axios";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
axios.defaults.headers.common["access-token"] =
  JSON.parse(localStorage.getItem("userData"))?.accessToken || "";
const AddUser = (props) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    password: "",
    email: "",
    phone: "",
    companyName: "",
    address: "",
    nameofsuppliers: "",
    postcode: "",
    role: "administrator",
  });
  const [successMessage, setSuccessMessage] = useState(null);
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // const requiredFields = document.querySelectorAll("[required]");

    // // Filter out the fields that are empty
    // const emptyFields = Array.from(requiredFields).filter(
    //   (field) => !field.value.trim()
    // );

    // if (emptyFields.length > 0) {
    //   // There are empty required fields
    //   const emptyFieldNames = emptyFields.map((field) => field.id).join(", ");
    //   console.log(`Please fill in the required fields: ${emptyFieldNames}`);
    // } else {
    //   // All required fields are filled
    //   console.log("Form submitted successfully!");
    // }
    // return;
    // if (formData.confirmpassword !== formData.password) {
    //   alert("Pasword and confirm password do not match");
    //   return;
    // }
    // e.preventDefault();
    // console.log("Form data:", formData, process.env.REACT_APP_BACKEND_URL);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/add`,
        formData
      );
      setSuccessMessage("User Added successfully!");

      setFormData({
        firstName: "",
        lastName: "",
        password: "",
        email: "",
        phone: "",
        companyName: "",
        address: "",
        nameofsuppliers: "",
        postcode: "",
        role: "administrator",
      });

      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (error) {
      console.error("Error occured while fetching theme", error);
      if (error.response) {
        alert("Error!!!", error.response.data);
      }
    }
  };
  const currentYear = new Date().getFullYear();
  return (
    <>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta name="description" content="" />
      <meta name="author" content="" />
      <title>Add User</title>
      <link
        href="https://cdn.jsdelivr.net/npm/simple-datatables@latest/dist/style.css"
        rel="stylesheet"
      />
      <link href="css/styles.css" rel="stylesheet" />
      <link rel="icon" type="image/x-icon" href="assets/img/favicon.png" />
      <Navbar toggleMobileMenu={props.toggleMobileMenu} />
      <div id="layoutSidenav">
        <SideBar
          setMobileMenuOpen={props.setMobileMenuOpen}
          isMobileMenuOpen={props.isMobileMenuOpen}
        />
        <div id="layoutSidenav_content">
          <main>
            <header className="page-header page-header-compact page-header-light border-bottom bg-white mb-4">
              <div className="container-xl px-4">
                <div className="page-header-content">
                  <div className="row align-items-center justify-content-between pt-3">
                    <div className="col-auto mb-3">
                      <h1 className="page-header-title">
                        <div className="page-header-icon">
                          <i data-feather="user" />
                        </div>
                        Add User
                      </h1>
                    </div>
                    <div className="col-12 col-xl-auto mb-3">
                      <a
                        onClick={() => {
                          navigate("/users");
                        }}
                        className="btn btn-sm btn-light text-primary"
                      >
                        <i className="me-1" data-feather="arrow-left" />
                        Back to Users List
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            {/* Main page content*/}
            <div className="container-xl px-4 mt-4">
              {successMessage && (
                <div className="alert alert-success" role="alert">
                  {successMessage}
                </div>
              )}
              <div className="row">
                <div className="col-xl-12">
                  {/* Account details card*/}
                  <div className="card mb-4">
                    <div className="card-header">Account Details</div>
                    <div className="card-body">
                      <form onSubmit={handleSubmit}>
                        {/* Form Row*/}
                        <div className="row gx-3 mb-3">
                          {/* Form Group (first name)*/}
                          <div className="col-md-6">
                            <label
                              className="small mb-1"
                              htmlFor="inputFirstName"
                            >
                              First name
                            </label>
                            <input
                              className="form-control"
                              id="firstName"
                              type="text"
                              placeholder="Enter your first name"
                              value={formData.firstName}
                              onChange={handleChange}
                              required
                            />
                          </div>
                          {/* Form Group (last name)*/}
                          <div className="col-md-6">
                            <label
                              className="small mb-1"
                              htmlFor="inputLastName"
                            >
                              Last name
                            </label>
                            <input
                              className="form-control"
                              id="lastName"
                              type="text"
                              placeholder="Enter your last name"
                              value={formData.lastName}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="row gx-3 mb-3">
                          <div className="col-md-6">
                            <label
                              className="small mb-1"
                              htmlFor="inputFirstName"
                            >
                              Password
                            </label>
                            <input
                              className="form-control"
                              id="password"
                              type="password"
                              placeholder="Enter your first name"
                              value={formData.password}
                              onChange={handleChange}
                              required
                            />
                          </div>
                          <div className="col-md-6">
                            <label
                              className="small mb-1"
                              htmlFor="inputFirstName"
                            >
                              Phone
                            </label>
                            <input
                              className="form-control"
                              id="phone"
                              type="text"
                              placeholder="Enter phone"
                              value={formData.phone}
                              onChange={handleChange}
                              required
                            />
                          </div>{" "}
                        </div>
                        {/* Form Group (email address)*/}
                        <div className="mb-3">
                          <label
                            className="small mb-1"
                            htmlFor="inputEmailAddress"
                          >
                            Email address
                          </label>
                          <input
                            className="form-control"
                            id="email"
                            type="email"
                            placeholder="Enter your email address"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        {/* Form Group (Group Selection Checkboxes)*/}
                        {/* Form Group (Roles)*/}
                        <div className="mb-3">
                          <label className="small mb-1">Role</label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            id="role"
                            value={formData.role}
                            onChange={handleChange}
                          >
                            <option disabled>Select a role:</option>
                            <option value="administrator">Administrator</option>
                            <option value="supplier">Supplier</option>
                            <option value="user">User</option>
                          </select>
                          {/* <select
  className="form-select"
  aria-label="Default select example"
  id="role"
  value={formData.role || 'administrator'} // Set the default value
  onChange={handleChange}
>
  <option disabled>Select a role:</option>
  <option value="administrator" selected>Administrator</option>
   <option value="supplier">supplier</option>
    <option value="user">user</option>
</select> */}
                        </div>
                        {/* Submit button*/}
                        <button className="btn btn-primary" type="submit">
                          Save changes
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <footer className="footer-admin mt-auto footer-light">
            <div className="container-xl px-4">
              <div className="row">
                <div className="col-md-6 small">
                  Copyright © Roof Pal {currentYear}
                </div>
                <div className="col-md-6 text-md-end small"></div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default AddUser;
