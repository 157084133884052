import "../PagesCss/Default.css";
import React, { Profiler, useState } from "react";
import SideBar from "./SideBar";
import { useNavigate } from "react-router";
import Navbar from "./Navbar";

import axios from "axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
axios.defaults.headers.common["access-token"] =
  JSON.parse(localStorage.getItem("userData"))?.accessToken || "";
const Security = (props) => {
  const [formData, setFormData] = useState({
    userId: JSON.parse(localStorage.getItem("userData")).userId || 0,
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form data:", formData);
    if (formData.newPassword !== formData.confirmPassword) {
      setPasswordsMatch(false);

      return;
    }

    delete formData.confirmPassword;
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/security/putsecurity`,
        formData
      );

      console.log("API Response:", response);
      NotificationManager.success("Password updated successfully", "Info");
      // Check if the response is successful
      if (response.status === 200) {
        console.log("Data updated successfully");
      } else {
        console.error("Error updating data:", response.statusText);
        NotificationManager.error(response.statusText, "Error");
      }
    } catch (error) {
      console.error("Error updating data:", error.message);
      NotificationManager.error(error.message, "Error");
    }
  };
  const currentYear = new Date().getFullYear();
  return (
    <>
      <Navbar toggleMobileMenu={props.toggleMobileMenu} />
      <div id="layoutSidenav">
        <SideBar
          setMobileMenuOpen={props.setMobileMenuOpen}
          isMobileMenuOpen={props.isMobileMenuOpen}
        />

        <div id="layoutSidenav_content">
          <main>
            <header className="page-header page-header-compact page-header-light border-bottom bg-white mb-4">
              <div className="container-xl px-4">
                <div className="page-header-content">
                  <div className="row align-items-center justify-content-between pt-3">
                    <div className="col-auto mb-3">
                      <ol
                        className="breadcrumb px-3 py-2 rounded mb-0"
                        id="SecurityMOb"
                      >
                        <li className="breadcrumb-item">
                          <a href="dashboard.html" id="SecurityMOb2">
                            Home
                          </a>
                        </li>
                        <li className="breadcrumb-item active">
                          Manage Account - Security
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            {/* Main page content*/}
            <div className="container-xl px-4 mt-4">
              {/* Account page navigation*/}
              <nav className="nav nav-borders">
                <a
                  className="nav-link active ms-0"
                  onClick={() => {
                    navigate("/manageaccount");
                  }}
                >
                  Profile
                </a>
                <a
                  className="nav-link active"
                  onClick={() => {
                    navigate("/Security");
                  }}
                >
                  Security
                </a>
              </nav>
              <hr className="mt-0 mb-4" />
              <div className="row">
                <div className="col-lg-12">
                  {/* Change password card*/}
                  <div className="card mb-8">
                    <div className="card-header">Change Password</div>
                    <div className="card-body">
                      <form onSubmit={handleSubmit}>
                        {/* Form Group (current password)*/}
                        <div className="mb-3">
                          <label
                            className="small mb-1"
                            htmlFor="currentPassword"
                          >
                            Current Password
                          </label>
                          <input
                            className="form-control"
                            id="currentPassword"
                            type="password"
                            placeholder="Enter current password"
                            value={formData.currentPassword}
                            onChange={handleChange}
                          />
                        </div>
                        {/* Form Group (new password)*/}
                        <div className="mb-3">
                          <label className="small mb-1" htmlFor="newPassword">
                            New Password
                          </label>
                          <input
                            className="form-control"
                            id="newPassword"
                            type="password"
                            placeholder="Enter new password"
                            value={formData.newPassword}
                            onChange={handleChange}
                          />
                        </div>
                        {/* Form Group (confirm password)*/}
                        <div className="mb-3">
                          <label
                            className="small mb-1"
                            htmlFor="confirmPassword"
                          >
                            Confirm Password
                          </label>
                          <input
                            className={`form-control ${
                              !passwordsMatch ? "is-invalid" : ""
                            }`}
                            id="confirmPassword"
                            type="password"
                            placeholder="Confirm new password"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                          />
                          {!passwordsMatch && (
                            <div className="invalid-feedback">
                              Passwords do not match
                            </div>
                          )}
                        </div>
                        <button className="btn btn-primary" type="submit">
                          Save
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <footer className="footer-admin mt-auto footer-light">
            <div className="container-xl px-4">
              <div className="row">
                <div className="col-md-6 small">
                  Copyright © Roof Pal {currentYear}
                </div>
                <div className="col-md-6 text-md-end small"></div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Security;
