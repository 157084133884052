import "../PagesCss/Default.css";
import React, { useState } from "react";
import favicon from "../assets/img/favicon.png";
import axios from "axios";
import { useNavigate } from "react-router";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
axios.defaults.headers.common["access-token"] =
  JSON.parse(localStorage.getItem("userData"))?.accessToken || "";
const AddTile = (props) => {
  const [formData, setFormData] = useState({
    tileName: "",
    tileTypeId: "3",
    costPerTile: "",
    tilesPerMetre: "",
    tileSlateNailsPM: "",
    battenPerMetre: "",
    battenNailsPSQM: "",
    battenRequired: "25x50mm",
    nailSize: "30mm",
    cementFibreSlate: "Yes",
    eaveCombFiller: "Yes",
    leftHandedTilePLM: "",
    leftHandedTileCost: "",
    ridgeRequired: "Concrete",
    costPerSlatenHalf: "",
    eaveTilesPerLM: "",
    costPerEaveTile: "",
    valleyTilesPerLM: "",
    costPerValleyTile: "",
    tilenHalfPerLM: "",
    costPerTilenHalf: "",
    bonnetsPerLM: "",
    costPerBonnet: "",
  });
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState(null);
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form data:", formData);

    try {
      // Make a POST request to your backend API
      //const response = await axios.post('http://localhost:8801/api/material/add', formData);
      const response = axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/tiles/add`,
        formData
      );
      setSuccessMessage("Data submitted successfully!");
      // Handle the response, e.g., show a success message
      console.log("Server response:", response.data);

      // Optionally, you can reset the form data after a successful submission
      setFormData({
        tileName: "",
        tileTypeId: "3",
        costPerTile: "",
        tilesPerMetre: "",
        tileSlateNailsPM: "",
        battenPerMetre: "",
        battenNailsPSQM: "",
        battenRequired: "",
        nailSize: "",
        cementFibreSlate: "",
        eaveCombFiller: "",
        leftHandedTilePLM: "",
        leftHandedTileCost: "",
        ridgeRequired: "",
        costPerSlatenHalf: "",
        eaveTilesPerLM: "",
        costPerEaveTile: "",
        valleyTilesPerLM: "",
        costPerValleyTile: "",
        tilenHalfPerLM: "",
        costPerTilenHalf: "",
        bonnetsPerLM: "",
        costPerBonnet: "",
      });
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (error) {
      // Handle errors, e.g., show an error message
      console.error("Error submitting data:", error.message);
    }
  };
  const currentYear = new Date().getFullYear();
  return (
    <>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta name="description" content="" />
      <meta name="author" content="" />
      <title>Add Tile</title>
      <link
        href="https://cdn.jsdelivr.net/npm/simple-datatables@latest/dist/style.css"
        rel="stylesheet"
      />
      <link href="css/styles.css" rel="stylesheet" />
      <link rel="icon" type="image/x-icon" href="assets/img/favicon.png" />
      <Navbar />
      <div id="layoutSidenav">
        <SideBar
          setMobileMenuOpen={props.setMobileMenuOpen}
          isMobileMenuOpen={props.isMobileMenuOpen}
        />
        <div id="layoutSidenav_content">
          <main>
            <header className="page-header page-header-compact page-header-light border-bottom bg-white mb-4">
              <div className="container-xl px-4">
                <div className="page-header-content">
                  <div className="row align-items-center justify-content-between pt-3">
                    <div className="col-auto mb-3">
                      <h1 className="page-header-title">
                        <div className="page-header-icon">
                          <i data-feather="user" />
                        </div>
                        Add Tile
                      </h1>
                    </div>
                    <div className="col-12 col-xl-auto mb-3">
                      <a
                        onClick={() => {
                          navigate("/tiles");
                        }}
                        className="btn btn-sm btn-light text-primary"
                      >
                        <i className="me-1" data-feather="arrow-left" />
                        Back to Tiles
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            {/* Main page content*/}
            <div className="container-xl px-4 mt-4">
              {successMessage && (
                <div className="alert alert-success" role="alert">
                  {successMessage}
                </div>
              )}
              <div className="row">
                <div className="col-xl-12">
                  {/* Account details card*/}
                  <div className="card mb-4">
                    <div className="card-header">Tile Details</div>
                    <div className="card-body">
                      <form onSubmit={handleSubmit}>
                        {/* Form Row*/}
                        <div className="row gx-3 mb-3">
                          {/* Form Group (first name)*/}
                          <div className="col-md-6">
                            <label
                              className="small mb-1"
                              htmlFor="inputFirstName"
                            >
                              Tile name
                            </label>
                            <input
                              className="form-control"
                              id="tileName"
                              type="text"
                              placeholder="Enter tile name"
                              value={formData.tileName}
                              onChange={handleChange}
                              required
                            />
                          </div>
                          {/* Form Group (last name)*/}
                          <div className="col-md-6">
                            <label className="small mb-1">Tile Type</label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              id="tileTypeId"
                              value={formData.tileTypeId}
                              onChange={handleChange}
                            >
                              <option disabled>Select a Title:</option>
                              <option value="1"> Slate</option>
                              <option value="2">Small Tiles</option>
                              <option value="3">Big Tiles</option>
                            </select>
                          </div>
                        </div>

                        <div className="row gx-3 mb-3">
                          {/* Form Group (first name)*/}
                          <div className="col-md-6">
                            <label
                              className="small mb-1"
                              htmlFor="inputFirstName"
                            >
                              Cost Per Tile&nbsp;
                            </label>
                            <input
                              className="form-control"
                              id="costPerTile"
                              type="number"
                              value={formData.costPerTile}
                              onChange={handleChange}
                              required
                            />
                          </div>
                          {/* Form Group (last name)*/}
                          <div className="col-md-6">
                            <label
                              className="small mb-1"
                              htmlFor="inputLastName"
                            >
                              Tiles Per Metre&nbsp;
                            </label>
                            <input
                              className="form-control"
                              id="tilesPerMetre"
                              type="number"
                              value={formData.tilesPerMetre}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>

                        <div className="row gx-3 mb-3">
                          {/* Form Group (first name)*/}
                          <div className="col-md-6">
                            <label
                              className="small mb-1"
                              htmlFor="inputFirstName"
                            >
                              Batten Per Metre&nbsp;
                            </label>
                            <input
                              className="form-control"
                              id="battenPerMetre"
                              type="number"
                              value={formData.battenPerMetre}
                              onChange={handleChange}
                              required
                            />
                          </div>
                          {/* Form Group (last name)*/}
                          <div className="col-md-6">
                            <label
                              className="small mb-1"
                              htmlFor="inputLastName"
                            >
                              Batten Nails Per SQM&nbsp;
                            </label>
                            <input
                              className="form-control"
                              id="battenNailsPSQM"
                              type="number"
                              value={formData.battenNailsPSQM}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>

                        <div className="row gx-3 mb-3">
                          {/* Form Group (first name)*/}
                          <div className="col-md-6">
                            <label
                              className="small mb-1"
                              htmlFor="inputFirstName"
                            >
                              Tile Slate Nails Per Meter&nbsp;
                            </label>
                            <input
                              className="form-control"
                              id="tileSlateNailsPM"
                              type="number"
                              value={formData.tileSlateNailsPM}
                              onChange={handleChange}
                              required
                            />
                          </div>
                          {/* Form Group (last name)*/}
                          <div className="col-md-6">
                            <label
                              className="small mb-1"
                              htmlFor="inputFirstName"
                            >
                              Batten Required&nbsp;
                            </label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              id="battenRequired"
                              value={formData.battenRequired}
                              onChange={handleChange}
                            >
                              <option disabled>Select Batten:</option>
                              <option value="25x50mm"> 25x50mm</option>
                              <option value="25x38mm">25x38mm</option>
                            </select>
                          </div>
                        </div>

                        <div className="row gx-3 mb-3">
                          {/* Form Group (first name)*/}
                          <div className="col-md-6">
                            <label
                              className="small mb-1"
                              htmlFor="inputLastName"
                            >
                              Nail Size&nbsp;
                            </label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              id="nailSize"
                              value={formData.nailSize}
                              onChange={handleChange}
                            >
                              <option disabled>Select NailSize:</option>
                              <option value="30mm"> 30mm</option>
                              <option value="35mm">35mm</option>
                              <option value="38mm"> 38mm</option>
                              <option value="40mm">40mm</option>
                              <option value="45mm"> 45mm</option>
                              <option value="50mm">50mm</option>
                              <option value="55mm"> 55mm</option>
                              <option value="60mm">60mm</option>
                              <option value="65mm"> 65mm</option>
                              <option value="70mm">70mm</option>
                              <option value="75mm"> 75mm</option>
                              <option value="N/A">N/A</option>
                            </select>
                          </div>
                          {/* Form Group (last name)*/}
                          <div className="col-md-6">
                            <label
                              className="small mb-1"
                              htmlFor="inputFirstName"
                            >
                              Cement Fibre Slate&nbsp;
                            </label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              id="cementFibreSlate"
                              value={formData.cementFibreSlate}
                              onChange={handleChange}
                            >
                              <option value="Yes"> Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                        </div>

                        <div className="row gx-3 mb-3">
                          {/* Form Group (first name)*/}
                          <div className="col-md-6">
                            <label
                              className="small mb-1"
                              htmlFor="inputLastName"
                            >
                              Eave Comb Filler&nbsp;
                            </label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              id="eaveCombFiller"
                              value={formData.eaveCombFiller}
                              onChange={handleChange}
                            >
                              <option value="Yes"> Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                          {/* Form Group (last name)*/}
                          <div className="col-md-6">
                            <label
                              className="small mb-1"
                              htmlFor="inputFirstName"
                            >
                              Left Handed Tile Per Linear Meter&nbsp;
                            </label>
                            <input
                              className="form-control"
                              id="leftHandedTilePLM"
                              type="number"
                              value={formData.leftHandedTilePLM}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="row gx-3 mb-3">
                          {/* Form Group (first name)*/}
                          <div className="col-md-6">
                            <label
                              className="small mb-1"
                              htmlFor="inputLastName"
                            >
                              Left Hand Tile Cost &nbsp;
                            </label>
                            <input
                              className="form-control"
                              id="leftHandedTileCost"
                              type="number"
                              value={formData.leftHandedTileCost}
                              onChange={handleChange}
                              required
                            />
                          </div>

                          <div className="col-md-6">
                            <label className="small mb-1">Ridge Required</label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              id="ridgeRequired"
                              value={formData.ridgeRequired}
                              onChange={handleChange}
                            >
                              <option disabled>Select a Ridge:</option>
                              <option value="Concrete">Concrete</option>
                              <option value="Clay">Clay</option>
                            </select>
                          </div>
                        </div>
                        {formData.tileTypeId == 1 && (
                          <div className="row gx-3 mb-3">
                            <div className="col-md-12">
                              <label
                                className="small mb-1"
                                htmlFor="inputFirstName"
                              >
                                Cost Per Slate & Half&nbsp;
                              </label>
                              <input
                                className="form-control"
                                id="costPerSlatenHalf"
                                type="text"
                                value={formData.costPerSlatenHalf}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        )}
                        {formData.tileTypeId == 2 && (
                          <div className="row gx-3 mb-3">
                            {/* Form Group (first name)*/}

                            {/* Form Group (last name)*/}
                            <div className="col-md-12">
                              <label
                                className="small mb-1"
                                htmlFor="inputLastName"
                              >
                                Eave Tiles Per LM&nbsp;
                              </label>
                              <input
                                className="form-control"
                                id="eaveTilesPerLM"
                                type="number"
                                value={formData.eaveTilesPerLM}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        )}
                        {formData.tileTypeId == 2 && (
                          <div className="row gx-3 mb-3">
                            {/* Form Group (first name)*/}
                            <div className="col-md-6">
                              <label
                                className="small mb-1"
                                htmlFor="inputFirstName"
                              >
                                Cost Per Eave Tile&nbsp;
                              </label>
                              <input
                                className="form-control"
                                id="costPerEaveTile"
                                type="number"
                                value={formData.costPerEaveTile}
                                onChange={handleChange}
                              />
                            </div>
                            {/* Form Group (last name)*/}
                            <div className="col-md-6">
                              <label
                                className="small mb-1"
                                htmlFor="inputLastName"
                              >
                                Valley Tiles Per LM&nbsp;
                              </label>
                              <input
                                className="form-control"
                                id="valleyTilesPerLM"
                                type="number"
                                value={formData.valleyTilesPerLM}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        )}
                        {formData.tileTypeId == 2 && (
                          <div className="row gx-3 mb-3">
                            {/* Form Group (first name)*/}
                            <div className="col-md-6">
                              <label
                                className="small mb-1"
                                htmlFor="inputFirstName"
                              >
                                Cost Per Valley Tile&nbsp;
                              </label>
                              <input
                                className="form-control"
                                id="costPerValleyTile"
                                type="number"
                                value={formData.costPerValleyTile}
                                onChange={handleChange}
                              />
                            </div>
                            {/* Form Group (last name)*/}
                            <div className="col-md-6">
                              <label
                                className="small mb-1"
                                htmlFor="inputLastName"
                              >
                                Cost Per Tile & Half&nbsp;
                              </label>
                              <input
                                className="form-control"
                                id="costPerTilenHalf"
                                type="number"
                                value={formData.costPerTilenHalf}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        )}
                        {formData.tileTypeId == 2 && (
                          <div className="row gx-3 mb-3">
                            {/* Form Group (first name)*/}
                            <div className="col-md-6">
                              <label
                                className="small mb-1"
                                htmlFor="inputFirstName"
                              >
                                Bonnets Per LM&nbsp;
                              </label>
                              <input
                                className="form-control"
                                id="bonnetsPerLM"
                                type="number"
                                value={formData.bonnetsPerLM}
                                onChange={handleChange}
                              />
                            </div>
                            {/* Form Group (last name)*/}
                            <div className="col-md-6">
                              <label
                                className="small mb-1"
                                htmlFor="inputLastName"
                              >
                                Cost Per Bonnet&nbsp;
                              </label>
                              <input
                                className="form-control"
                                id="costPerBonnet"
                                type="number"
                                value={formData.costPerBonnet}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        )}
                        {formData.tileTypeId == 2 && (
                          <div className="row gx-3 mb-3">
                            {/* Form Group (first name)*/}
                            <div className="col-md-6">
                              <label
                                className="small mb-1"
                                htmlFor="inputFirstName"
                              >
                                Tile & Half Per LM&nbsp;
                              </label>
                              <input
                                className="form-control"
                                id="tilenHalfPerLM"
                                type="text"
                                value={formData.tilenHalfPerLM}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        )}

                        <button className="btn btn-primary" type="submit">
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <footer className="footer-admin mt-auto footer-light">
            <div className="container-xl px-4">
              <div className="row">
                <div className="col-md-6 small">
                  Copyright © Roof Pal {currentYear}
                </div>
                <div className="col-md-6 text-md-end small"></div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default AddTile;
