import "../PagesCss/Default.css";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from "@mui/material";
import { Delete, Edit, Download } from "@mui/icons-material";
import { useNavigate } from "react-router";
import favicon from "../assets/img/favicon.png";
import axios from "axios";
import React, { useState, useEffect } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
axios.defaults.headers.common["access-token"] =
  JSON.parse(localStorage.getItem("userData"))?.accessToken || "";
const Material = (props) => {
  const [materialData, setmaterialsData] = useState([]);
  const defaultMaterialTheme = createTheme();
  const navigate = useNavigate();
  console.log("materialData5", materialData);
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/material/get`
      );
      console.log("materialData6", response.data.result);
      console.log(Array.isArray(response.data.result)); // This will log true if it's an array, false otherwise.
      // const modifiedData = response.data.result.map((nail) => ({
      //   ...nail,
      //   nailSize: `${nail.nailSize}${nail.unit}`,
      //   id: nail.id,
      // }));

      setmaterialsData(response.data.result); // Assuming the response is an array of nail data
      //setNailsData(modifiedData);
      //  console.log("modified",modifiedData);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {
    fetchData(); // Invoke the fetchData function when the component mounts
  }, []); // Empty dependency array ensures that the effect runs only once

  const handleEdit = (event, rowData) => {
    console.log(rowData);
    navigate(`/EditMaterials/${rowData.materialId}`); // Assuming rowData contains the 'id' property
  };
  const handleDelete = async (oldData) => {
    try {
      // Assuming you have the correct endpoint for deleting data
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/material/delete/${oldData.materialId}`
      );

      console.log("Delete Response:", response);
      NotificationManager.success("User deleted successfully", "Success");
      // You can handle the response as needed
      // For example, you can show a success message or update the table data

      // Fetch updated data after deletion
      fetchData();
    } catch (error) {
      console.error("Error deleting data:", error.message);
      // You can handle errors, for example, display an error message to the user
    }
  };
  const columns = [
    { title: "Material Name", field: "materialName" },
    { title: "Amount Per Unit", field: "amountPerUnit" },
    { title: "Cost Per Unit", field: "costPerUnit" },
  ];
  const currentYear = new Date().getFullYear();
  return (
    <>
      <Navbar toggleMobileMenu={props.toggleMobileMenu} />
      <div id="layoutSidenav">
        <SideBar
          setMobileMenuOpen={props.setMobileMenuOpen}
          isMobileMenuOpen={props.isMobileMenuOpen}
        />
        <div id="layoutSidenav_content">
          <main>
            <header className="page-header page-header-compact page-header-light border-bottom bg-white mb-4">
              <div className="container-xl px-4">
                <div className="page-header-content">
                  <div className="row align-items-center justify-content-between pt-3">
                    <div className="col-auto mb-3">
                      <h1 className="page-header-title">
                        <div className="page-header-icon">
                          <i data-feather="user" />
                        </div>
                        Materials&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                      </h1>
                    </div>
                    <div className="col-12 col-xl-auto mb-3">
                      <a
                        onClick={() => {
                          navigate("/AddMaterials");
                        }}
                        className="btn btn-sm btn-light text-primary"
                      >
                        <i className="me-1" data-feather="user-plus" />
                        Add New Materials
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            {/* Main page content*/}
            {/* Main page content*/}
            <div className="container-xl px-4 mt-4">
              <div>
                <div>
                  <ThemeProvider theme={defaultMaterialTheme}>
                    <MaterialTable
                      title="Material Details"
                      columns={columns}
                      data={materialData}
                      options={{
                        search: true,
                        sorting: true,
                        paging: true,
                        actionsColumnIndex: -1,
                      }}
                      actions={[
                        {
                          icon: () => <Edit />,
                          tooltip: "Edit/Display",
                          onClick: (event, rowData) => {
                            console.log("MaterialTable Row Data:", rowData);
                            // Handle edit action
                            handleEdit(event, rowData);
                          },
                        },
                      ]}
                      editable={{
                        onRowDelete: (oldData) =>
                          new Promise((resolve) => {
                            // Display a confirmation dialog
                            // const shouldDelete = window.confirm('Are you sure you want to delete this nail?');

                            // Handle delete action
                            handleDelete(oldData);
                            resolve();
                          }),
                      }}
                    />
                  </ThemeProvider>
                </div>
              </div>
            </div>
          </main>
          <footer className="footer-admin mt-auto footer-light">
            <div className="container-xl px-4">
              <div className="row">
                <div className="col-md-6 small">
                  Copyright © Roof Pal {currentYear}
                </div>
                <div className="col-md-6 text-md-end small"></div>
              </div>
            </div>
          </footer>
        </div>
      </div>
      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Delete Tile
              </h5>
              <button
                className="btn-close"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              Are you sure you wish to delete this material?
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-primary"
                type="button"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button className="btn btn-danger" type="button">
                Delete Tile
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Material;
