import "../PagesCss/Default.css";
import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
axios.defaults.headers.common["access-token"] =
  JSON.parse(localStorage.getItem("userData"))?.accessToken || "";
const Contact = (props) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    comments: "",
  });
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.companyName ||
      !formData.email
    ) {
      setErrorMessage("Please fill in all required fields.");
      return;
    }
    console.log("Form data:", formData);
    formData.userName = JSON.parse(localStorage.getItem("userData")).firstName;
    try {
      // Make a POST request to your backend API
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/contact/add`,
        formData
      );
      setSuccessMessage("Data submitted successfully!");

      // Handle the response, e.g., show a success message
      console.log("Server response:", response.data);

      // Optionally, you can reset the form data after a successful submission
      setFormData({
        firstName: "",
        lastName: "",
        companyName: "",
        email: "",
        comments: "",
        phone: "",
      });
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (error) {
      // Handle errors, e.g., show an error message
      console.error("Error submitting data:", error.message);
      setErrorMessage("Error submitting data. Please try again.");
    }
  };
  const currentYear = new Date().getFullYear();
  return (
    <>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta name="description" content="" />
      <meta name="author" content="" />
      <title>Dashboard</title>
      <link
        href="https://cdn.jsdelivr.net/npm/simple-datatables@latest/dist/style.css"
        rel="stylesheet"
      />
      <link href="css/styles.css" rel="stylesheet" />
      <link rel="icon" type="image/x-icon" href="assets/img/favicon.png" />
      <Navbar toggleMobileMenu={props.toggleMobileMenu} />
      <div id="layoutSidenav">
        <SideBar
          setMobileMenuOpen={props.setMobileMenuOpen}
          isMobileMenuOpen={props.isMobileMenuOpen}
        />
        <div id="layoutSidenav_content">
          <main>
            <header className="page-header page-header-compact page-header-light border-bottom bg-white mb-4">
              <div className="container-xl px-4">
                <div className="page-header-content">
                  <div className="row align-items-center justify-content-between pt-3">
                    <div className="col-auto mb-3">
                      <ol className="breadcrumb px-3 py-2 rounded mb-0">
                        <li className="breadcrumb-item">
                          <a href="dashboard.html">Home</a>
                        </li>
                        <li className="breadcrumb-item active">Contact Us</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            {/* Main page content*/}
            {/* Main page content*/}
            <div className="container-xl px-4 mt-4">
              {errorMessage && (
                <div className="alert alert-danger" role="alert">
                  {errorMessage}
                </div>
              )}

              {successMessage && (
                <div className="alert alert-success" role="alert">
                  {successMessage}
                </div>
              )}
              <div className="container-xl">
                <div className="card">
                  <div className="card-header">Contact Us</div>
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      {/* Form Row*/}
                      <div className="row gx-3">
                        <div className="col-md-6">
                          {/* Form Group (first name)*/}
                          <div className="mb-3">
                            <label
                              className="small mb-1"
                              htmlFor="inputFirstName"
                            >
                              First Name
                            </label>
                            <input
                              className="form-control"
                              id="firstName"
                              type="text"
                              placeholder="Enter first name"
                              value={formData.firstName}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          {/* Form Group (last name)*/}
                          <div className="mb-3">
                            <label
                              className="small mb-1"
                              htmlFor="inputLastName"
                            >
                              Last Name
                            </label>
                            <input
                              className="form-control"
                              id="lastName"
                              type="text"
                              placeholder="Enter last name"
                              value={formData.lastName}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row gx-3">
                        <div className="col-md-6">
                          {/* Form Group (first name)*/}
                          <div className="mb-3">
                            <label
                              className="small mb-1"
                              htmlFor="inputCompanyName"
                            >
                              Company Name
                            </label>
                            <input
                              className="form-control"
                              id="companyName"
                              type="text"
                              aria-describedby="companyHelp"
                              placeholder="Enter company name"
                              value={formData.companyName}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          {/* Form Group (last name)*/}
                          <div className="mb-3">
                            <label
                              className="small mb-1"
                              htmlFor="inputLastName"
                            >
                              Phone
                            </label>
                            <input
                              required
                              className="form-control"
                              id="phone"
                              type="text"
                              placeholder="Enter phone"
                              value={formData.phone}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                      {/* Form Group (email address)            */}
                      <div className="mb-3">
                        <label
                          className="small mb-1"
                          htmlFor="inputEmailAddress"
                        >
                          Email
                        </label>
                        <input
                          className="form-control"
                          id="email"
                          type="email"
                          aria-describedby="emailHelp"
                          placeholder="Enter email address"
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </div>
                      {/* Form Group comments            */}
                      <div className="mb-3">
                        <label
                          className="small mb-1"
                          htmlFor="exampleFormControlTextarea1"
                        >
                          Comments
                        </label>
                        <textarea
                          className="form-control"
                          id="comments"
                          rows={5}
                          value={formData.comments}
                          onChange={handleChange}
                        />
                      </div>
                      <button className="btn btn-primary" type="submit">
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <footer className="footer-admin mt-auto footer-light">
            <div className="container-xl px-4">
              <div className="row">
                <div className="col-md-6 small">
                  Copyright © Roof Pal {currentYear}
                </div>
                <div className="col-md-6 text-md-end small"></div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Contact;
